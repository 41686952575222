import React, { useState } from 'react';
import { Calendar, Modal, Button, Alert, Empty, theme, Row, Col, Divider, notification, message } from 'antd';
import moment from 'moment';
import * as GetSlots from './slots'
import './calender.css'
import ConfirmModal from '../../../components/modal/confirm.modal';
import MobileFooter from './mobile-footer/mobile.footer';
import { useEffect } from 'react';
import { getData, postData } from '../../../actions/middleware';
import { routes } from '../../../actions/api-routes';
import Loader from '../../../components/loader/loader';

const SlotBooking = ({ data, previous, id, refresh }) => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectButton, setSelectButton] = useState({});
  const [selectedSlot, setSelectedSlot] = useState();
  const [loading, setLoading] = useState(false);
  const [eachSlotFor, setEachSlotFor] = useState(60);

  const [open, setIsOpen] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([]);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = () => {
    api['warning']({
      message: 'Please make sure with your information.',
      description:
        <Alert type='warning' message="Please note, only book your slot if you are available during the specified time. If you book a slot and are later found unavailable, your profile may be rejected, and you won't be able to reapply for 6 months. Ensure the provided information is accurate and verified; this ensures a successful and lasting collaboration with accelgrowth." />,
      placement: 'bottomLeft',
      duration: 120,
      style: { width: '500px' }
    });
  };

  useEffect(() => {
    if (data) {
      openNotificationWithIcon()
      onDateSelect(new Date())
    }
  }, [data])


  const onDateSelect = (date) => {
    setSelectButton({})
    setSelectedSlot(null)
    setSelectedDate(new Date(date));
    getSlots(new Date(date))
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };

  const onSelectSlot = (ele) => {
    const button = {}
    button[ele] = 'primary'
    setSelectButton(button)
    setSelectedSlot(ele)
  }

  const getSlots = async (_selectedDate) => {
    const newDate = moment().subtract(1, 'days');
    if (moment(_selectedDate).isBefore(newDate)) {
      return []
    } else {
      const resData = await getData(routes.slots.listSlots(data.appliedFor.interviewer, moment(_selectedDate).format("dddd"), moment(_selectedDate)))
      if (resData.code == 200) {
        setAvailableSlots(resData.data);
      } else {
        setAvailableSlots([])
      }
    }
  }

  function parseTimeString(date, timeString, minute = null) {
    const [hours, minutes, ampm] = timeString.match(/(\d{2}):(\d{2})([ap]m)/i).slice(1);
    let hour = parseInt(hours, 10);
    if (ampm.toLowerCase() === 'pm' && hour !== 12) {
      hour += 12;
    }
    const myMoment = moment(date).set({ hour, minute: parseInt(minutes, 10), second: 0, millisecond: 0 });
    if (minute) {
      return myMoment.clone().add(parseInt(minute), 'minutes');
    } else {
      return myMoment;
    }
  }

  const bookSlot = async () => {
    try {
      setLoading(true)
      const payload = {
        startDateTime: parseTimeString(selectedDate, selectedSlot),
        endDateTime: parseTimeString(selectedDate, selectedSlot, eachSlotFor),
        tempId: id,
        day: moment(selectedDate).format("dddd")
      }

      const resData = await postData(routes.slots.bookSlot, payload);
      if (resData && resData.code == 200) {
        setLoading(false)
        refresh()
      } else {
        message.error('Something went wrong please try again later')
        setLoading(false)
      }
    } catch (error) {
      setLoading(true)
      message.error('Something went wrong please try again later')
    }
  }

  const onSubmit = () => {
    setIsOpen(true)
  }

  const { token } = theme.useToken();
  const wrapperStyle = {
    width: 500,
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.Alert,
    padding: '5px'
  };

  return (
    <div className='viewBox-calender'>
      {loading && <Loader />}
      {contextHolder}
      <ConfirmModal
        open={open}
        handleOk={() => {
          bookSlot()
          setIsOpen(false)
        }}
        handleCancel={() => setIsOpen(false)}
      />
      <Row gutter={16} className='padding-top30'>
        <Col xs={24} sm={24} md={24} lg={12}>
          <div className='calender-booking' style={wrapperStyle}>
            <Calendar
              mode="month"
              style={{ padding: 0, width: '100%' }}
              onSelect={onDateSelect}
              disabledDate={disabledDate}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} >
          <div className='slot-body'>
            <Row gutter={16} align={'middle'}>
              {availableSlots.map(ele =>
                <Col className='padding-bottom-15' xs={8} sm={6} md={6} lg={4} >
                  <Button size='large' type={(selectButton && selectButton[ele]) ? selectButton[ele] : 'dashed'}
                    onClick={() => onSelectSlot(ele)}>
                    {ele}
                  </Button>
                </Col>)}
              {(availableSlots.length == 0) && <Empty className="center vertical-middle" />}
            </Row>
          </div>
          <div className='footer d-dextop'>
            <Divider className='divider' />
            <div className='book-slot'>
              <Button className='button-view margin-lr' size='large' onClick={previous}>Previous</Button>
              <Button
                className='button-view'
                disabled={!Object.keys(selectButton).length}
                size='large'
                onClick={() => onSubmit()}
                type='primary'>
                Book Slot
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <MobileFooter nextButtonText='Book a Slot' next={onSubmit} previous={previous} isPrevious={true} />
    </div >
  );
};

export default SlotBooking;
