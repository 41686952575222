// AdminRoutes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginEmployee from '../interview/login';
import JobDiscriptions from '../interview/job-discriptions/JobDiscriptions';

const InterviewRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/:id" element={<JobDiscriptions />} />
      </Routes>
    </>
  );
};

export default InterviewRoutes;
