import React from 'react';
import { Form, Select, Input, Checkbox, DatePicker, Radio, Button, Divider, message } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import PdfViewer from '../../../components/PdfViewer';
import { LinkOutlined } from '@ant-design/icons';
import MobileFooter from './mobile-footer/mobile.footer';
import { getData, postData, putData } from '../../../actions/middleware';
import { routes } from '../../../actions/api-routes';
const { useForm } = Form;

const { Option } = Select;

const Questions = ({ data, next, previous, id }) => {
  const [questionsData, setQuestionsData] = useState([]);
  const [isVisible, setVisible] = useState(false)
  const [questionsAns, setQuestionsAns] = useState(null)

  const [form] = useForm();

  useEffect(() => {
    if (data && data.appliedFor.interviewQuestions) {
      setQuestionsData(transformFields(data.appliedFor.interviewQuestions))
      getQuestionsData();
    }
  }, [data])

  const onSubmit = () => {
    form.submit();
  }

  const transformFields = (inputFields) => {
    const outputFields = [];
    inputFields.forEach((field) => {
      const outputField = {
        label: field.label,
        ans: {
          fieldType: 'text',
          ...(field.fieldType && { fieldType: field.fieldType }),
          ...(field.options && { options: field.options.split(',').map(option => option.trim()) }),
          required: field.required === 'Yes',
        },
      };
      outputFields.push(outputField);
    });
    return outputFields;
  }

  const getQuestionsData = async () => {
    const resData = await getData(routes.interviewQuestions + data._id)
    if (resData && resData.code == 200) {
      setQuestionsAns(resData.data)
      form.setFieldsValue(resData.data.questions)
    }
  }

  function formatExperienceDetails(experience) {
    const formattedExperience = experience.replace(/[^\w\s]/gi, '').replace(/\s+/g, '_');
    return formattedExperience;
  }

  const onFinish = async (values) => {
    console.log('Form values:', values);
    if (questionsAns) {
      const resData = await putData(routes.interviewQuestions + questionsAns._id, { questions: values })
      if (resData && resData.code == 200) {
        message.success("Successfully updated your response")
        next()
      } else {
        message.error("Something went wrong, please try again")
      }
    } else {
      const payload = {
        questions: values,
        tempToken: id
      }
      const resData = await postData(routes.interviewQuestions, payload)
      if (resData && resData.code == 200) {
        message.success("Successfully added your response")
        next()
      } else {
        message.error("Something went wrong, please try again")
      }
    }
  };

  return (
    <>
      <div className='row'>
        <div className='col-lg-3 profile-view-box'>
          <h6>My Profile</h6>
          <div className='profile-view'>
            <div className='profile-box'>
              <Divider className='divider' />
              <div className='profile-lable'>
                Name
              </div>
              {data?.candidateName} | {data?.candidateType} ({data?.experiance} Years)
              <br />
              <Divider className='divider' />
            </div>
            <div className='profile-box'>
              <div className='profile-lable'>
                Contact Details
              </div>
              {data?.email}
              <br />
              {data?.phone}
              <Divider className='divider' />
            </div>
            <div className='profile-box'>
              <div className='profile-lable'>
                Salary
              </div>
              Current CTC - {data?.currentCTC} <br />
              Expected CTC - {data?.expectedCTC}
              <Divider className='divider' />
            </div>
            <div className='profile-box'>
              <div className='profile-lable'>
                Last Organization
              </div>
              Notice Period - {data?.noticePeriod} <br />
              Reason Of Leaving - {data?.reasonOfLeaving}
              <Divider className='divider' />
            </div>
            <div className='profile-box'>
              <div className='profile-lable'>
                Resume
              </div>
              <Button
                onClick={() => setVisible(true)}
                type='link'
                className='no-padding'
                icon={<LinkOutlined />}>
                View Resume
              </Button>
              <PdfViewer
                className='react-model'
                title={'Resume'}
                isOpen={isVisible}
                onClose={() => setVisible(false)}
                pdfUrl={`${routes.fileServer}${data?.resume}`}
              >
              </PdfViewer>
            </div>
          </div>
        </div>
        <div className='col-lg-9 scroll-view-box'>
          <div>
            <div className='heading-padding'>
              <h6>Questions</h6>
              <div className='button-box d-dextop'>
                <Button className='button-view margin-lr' onClick={previous}>Previous</Button>
                <Button className='button-view' onClick={() => onSubmit()} type='primary'>Save & Next</Button>
              </div>
            </div>
            <Divider className='divider' />
            <p className='scroll-view'>
              <Form
                form={form}
                onFinish={onFinish}
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 16 }}
                layout='vertical'
              >
                {questionsData.map((field, index) => (
                  <Form.Item
                    key={index}
                    label={field.label}
                    name={formatExperienceDetails(field.label)}
                    rules={[
                      { required: field.ans.required, message: 'This field is required' },
                      ...(field.ans.fieldType === 'number'
                        ? []
                        : [])
                    ]}
                  >
                    {field.ans.fieldType === 'select' ? (
                      <Select>
                        {field.ans.options.map((option, optionIndex) => (
                          <Option key={optionIndex} value={option}>
                            {option}
                          </Option>
                        ))}
                      </Select>
                    ) : field.ans.fieldType === 'checkbox' ? (
                      <Checkbox.Group options={field.ans.options} />
                    ) : field.ans.fieldType === 'radio' ? (
                      <Radio.Group>
                        {field.ans.options.map((option, optionIndex) => (
                          <Radio key={optionIndex} value={option}>
                            {option}
                          </Radio>
                        ))}
                      </Radio.Group>
                    ) : field.ans.fieldType === 'date' ? (
                      <DatePicker />
                    ) : field.ans.fieldType === 'textarea' ? (
                      <Input.TextArea />
                    ) : (
                      <Input type={field.ans.fieldType} />
                    )}
                  </Form.Item>
                ))}
              </Form>
            </p>
          </div>
        </div>
      </div>
      <MobileFooter nextButtonText='Save & Next' next={onSubmit} previous={previous} isPrevious={true} />
    </>
  );
};

export default Questions;
