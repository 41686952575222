import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './pages/header';
import Footer from './pages/footer';
import { useEffect } from 'react';
import MainSiteRoutes from './routings/MainSiteRoutes';
import Admin from './admin';
import AuthRoutes from './routings/AuthRoutes';
import InterviewRoutes from './routings/InterviewRoutes';

import { Provider } from 'react-redux';
import store from './store';
import OnlineExamRoutes from './routings/OnlineExamRoutes';
import ExtraRoutes from './routings/ExtraRoutes';
import PublicRoutes from './routings/publicRoutes';
import TextToSpeechRouting from './routings/TextToSpeechRouting';

function App() {

  const LoadExternalScript = (ele) => {
    const externalScript = document.createElement("script");
    externalScript.id = "external";
    externalScript.async = true;
    externalScript.type = "text/javascript";
    externalScript.setAttribute("crossorigin", "anonymous");
    document.body.appendChild(externalScript);
    externalScript.src = ele;
  };

  useEffect(() => {
    [
      "./assets/js/vendor/modernizr-2.8.3.min.js",
      "./assets/js/vendor/jquery-migrate-3.3.0.min.js",
      "./assets/js/vendor/bootstrap.min.js",
      "./assets/js/plugins/plugins.min.js",
      "./assets/js/main.js"
    ].forEach(ele => {
      LoadExternalScript(ele);
    })
  });

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={
              <>
                <Header />
                <MainSiteRoutes />
                <Footer />
              </>
            } />
          <Route path="admin/*" element={<Admin />} />
          <Route path="auth/*" element={<AuthRoutes />} />
          <Route path="interview/*" element={<InterviewRoutes />} />
          <Route path='test/*' element={<OnlineExamRoutes />} />
          <Route path='extra/*' element={<ExtraRoutes />} />
          <Route path='public/*' element={<PublicRoutes />} />
          <Route path='text-to-speech/*' element={<TextToSpeechRouting />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
