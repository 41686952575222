import React, { useEffect } from 'react';
import { Form, Select, Input, Checkbox, DatePicker, Radio, Divider, Button, Upload, Modal, message, Row, Col } from 'antd';
import { UploadOutlined, EyeOutlined } from '@ant-design/icons';

import Dailog from '../../../components/modal';
import PdfViewer from '../../../components/PdfViewer';
import { useState } from 'react';
import { LinkOutlined } from '@ant-design/icons';
import MobileFooter from './mobile-footer/mobile.footer';
import { routes } from '../../../actions/api-routes';
import './profile.css'
import { getData, putData } from '../../../actions/middleware';
const { Option } = Select;
const { useForm } = Form;

const Profile = ({ data, next, id }) => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [experiance, setExperiance] = useState('');
  const [resProfile, setResData] = useState();

  const [form] = useForm()

  useEffect(() => {
    if (id) {
      getResponse(id);
    }
  }, [id]);

  useEffect(() => {
    if (resProfile && form) {
      form.setFieldsValue(resProfile)
      if (resProfile.resume) {
        setPdfUrl(resProfile.resume)
        form.setFieldValue('pdf', [{ uid: '-1', name: resProfile.resume.split('/').pop(), status: 'done' }])
      }
      if (resProfile.candidateType) {
        setExperiance(resProfile.candidateType)
      }
    }
  }, [resProfile])

  const getResponse = async (_id) => {
    const resData = await getData(routes.public.ownProfile + _id)
    if (resData) {
      setResData(resData.data)
    }
  }

  const onFinish = async (values) => {
    if (!pdfUrl) {
      message.error('Please upload a PDF file before submitting.');
      return;
    } else {
      const resData = await putData(routes.profileURL + id, { ...values, resume: pdfUrl })
      if (resData && resData.code == 200) {
        message.success(`Successfully updated`);
        next();
      } else {
        message.error(`Something went wrong please try again later`);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const showViewModal = () => {
    setViewModalVisible(true);
  };

  const hideViewModal = () => {
    setViewModalVisible(false);
  };

  const beforeUpload = (file) => {
    const isPdf = file.type === 'application/pdf';
    if (!isPdf) {
      message.error('You can only upload PDF files!');
    }
    return isPdf;
  };

  const handleUploadChange = (info) => {
    if (info.file && info.file.response && info.file.response.status === 'SUCCESS') {
      setPdfUrl(info.file.response.files[0]);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      setPdfUrl(null);
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const phoneNumberValidator = (_, value) => {
    const phoneNumberRegex = /^[0-9]{10}$/;
    if (!value || phoneNumberRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('Please enter a valid 10-digit phone number!');
  };


  const handleFileNameClick = () => {
    if (pdfUrl) {
      showViewModal();
    }
  };

  const onSubmitForm = () => {
    form.submit();
  }


  return (
    <>
      <div className='row'>
        <div className='col-lg-8 scroll-view-box justify-bet'>
          <div>
            <div className='heading-padding'>
              <h6>Profile</h6>
              <Button className='button-view d-dextop' onClick={onSubmitForm} type='primary'>Save & Next</Button>
            </div>
            <Divider className='divider' />
            <div className='scroll-view'>
              <div className='row interview-form'>
                <Form
                  form={form}
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout='vertical'
                  initialValues={resProfile}
                >
                  <Row gutter={16} justify={'space-around'}>
                    <Col lg={11} md={11} sm={24}>
                      <Form.Item
                        label="Full Name"
                        name="candidateName"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your full name',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col lg={11} md={11} sm={24}>
                      <Form.Item
                        label="Phone"
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your contact number!',
                          },
                          {
                            validator: phoneNumberValidator,
                          },
                        ]}
                      >
                        <Input prefix={'+91'} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} justify={'space-around'}>
                    <Col lg={11} md={11} sm={24}>
                      <Form.Item
                        label="Gender"
                        name="gender"
                        rules={[
                          {
                            required: true,
                            message: 'Please select your gender!',
                          },
                        ]}
                      >
                        <Select>
                          <Select.Option value="Male">Male</Select.Option>
                          <Select.Option value="Female">Female</Select.Option>
                          <Select.Option value="Other">Other</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={11} md={11} sm={24}>
                      <Form.Item
                        label="Experience level"
                        name="candidateType"
                        rules={[
                          {
                            required: true,
                            message: 'Please select experience level!',
                          },
                        ]}
                      >
                        <Select onChange={setExperiance}>
                          <Select.Option value="Intern">Intern</Select.Option>
                          <Select.Option value="Fresher">Fresher</Select.Option>
                          <Select.Option value="Experience">Experience</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16} justify={'space-around'}>
                    <Col lg={11} md={11} sm={24}>
                      <Form.Item
                        label="Internship Experiance"
                        name="internshipExperiance"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter your internship experiance!',
                          },
                        ]}
                      >
                        <Input type='number' suffix={'Years'} />
                      </Form.Item>
                    </Col>
                    {experiance !== 'Experience' && <Col lg={11} md={11} sm={24}></Col>}
                    {experiance == 'Experience' && <Col lg={11} md={11} sm={24}>
                      <Form.Item
                        label="Total Experiance"
                        name="experiance"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter total experiance!',
                          },
                        ]}
                      >
                        <Input type='number' suffix={'Years'} />
                      </Form.Item>
                    </Col>}
                  </Row>

                  {experiance == 'Experience' && <Row gutter={16} justify={'space-around'}>
                    <Col lg={11} md={11} sm={24}>
                      <Form.Item
                        label="Current CTC"
                        name="currentCTC"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter CTC!',
                          },
                        ]}
                      >
                        <Input type='number' prefix={'INR'} suffix={'LPA'} />
                      </Form.Item>
                    </Col>
                    <Col lg={11} md={11} sm={24}>
                      <Form.Item
                        label="Expected CTC"
                        name="expectedCTC"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter expected CTC!',
                          },
                        ]}
                      >
                        <Input type='number' prefix={'INR'} suffix={'LPA'} />
                      </Form.Item>
                    </Col>
                  </Row>}
                  <Row gutter={16} justify={'space-around'}>
                    <Col lg={11} md={11} sm={24}>
                      <Form.Item
                        label="Notice Periods"
                        name="noticePeriod"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter notice period!',
                          },
                        ]}
                      >
                        <Input type='number' suffix={'Days'} />
                      </Form.Item>
                    </Col>
                    <Col lg={11} md={11} sm={24}></Col>
                  </Row>
                  <Row gutter={16} justify={'space-around'}>
                    <Col lg={11} md={11} sm={24}>
                      <Form.Item
                        label="Upload Resume"
                        name="pdf"
                        rules={[
                          {
                            required: true,
                            message: 'Please upload a PDF file!',
                          },
                        ]}
                      >
                        <Upload
                          maxCount={1}
                          beforeUpload={beforeUpload}
                          onChange={handleUploadChange}
                          action={`${routes.fileServer}/api/upload/add`}
                          showUploadList={false}
                        >
                          <Button icon={<UploadOutlined />}>
                            Upload PDF
                          </Button>
                        </Upload>
                      </Form.Item>

                      {pdfUrl && (
                        <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={handleFileNameClick}>
                          {pdfUrl.split('/').pop()}
                        </span>
                      )}
                    </Col>
                    <Col lg={11} md={11} sm={24}></Col>
                  </Row>
                  <Modal
                    title="View PDF"
                    open={viewModalVisible}
                    onCancel={hideViewModal}
                    footer={null}
                    destroyOnClose
                  >
                    <iframe title="PDF Viewer" src={routes.fileServer + pdfUrl} style={{ width: '100%', height: '500px' }} />
                  </Modal>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div >
      <MobileFooter nextButtonText={'Save & Next'} next={onSubmitForm} />
    </>
  );
};

export default Profile;
