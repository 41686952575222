import React, { useState } from 'react';
import { Alert, Button, Modal, Space } from 'antd';
import { LinkOutlined } from '@ant-design/icons';

const ConfirmModal = ({ open, handleOk, handleCancel }) => {

  return (
    <>
      <Modal
        open={open}
        title="PLEASE READ AND CONFIRM"
        okText="I Agree & Continue"
        onOk={handleOk}
        closeIcon={false}
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <Alert type='warning' message="Ensure your provided information is correct. Book an interview slot only if 100% sure and available; failure to attend results in profile rejection, restricting reapplication for 6 months." />
      </Modal>
    </>
  );
};
export default ConfirmModal;