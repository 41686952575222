import CryptoJS from 'crypto-js';

const aesKey = CryptoJS.enc.Utf8.parse("000102030405060708090a0b0c0d0e0f101112131415161718191a1b1c1d1e1f");
const aesIv = CryptoJS.enc.Utf8.parse("000102030405060708090a0b0c0d0e0f");
const aesOptions = {
    iv: aesIv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
};

const encryptData = (data) => {
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), aesKey, aesOptions).ciphertext.toString();
    return encrypted
}

const decryptData = (data) => {
    const encoded = { ciphertext: CryptoJS.enc.Hex.parse(data) };
    const decodedText = CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encoded, aesKey, aesOptions)); // 'iamtheoriginal'
    const decryptedData = JSON.parse(decodedText);
    return decryptedData
}

// const encryptData = (data) => {
//     const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), crypto_key).toString();
//     return encryptedData;
// };

// const decryptData = (encryptedData) => {
//     const bytes = CryptoJS.AES.decrypt(encryptedData, crypto_key);
//     const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//     return decryptedData;
// };

export { encryptData, decryptData }