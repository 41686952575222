import React from 'react';
import './loader.css';
import { Spin } from 'antd';

const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader-container">
        <Spin size="large" />
        <p>Loading...</p>
      </div>
    </div>
  );
}

export default Loader;
