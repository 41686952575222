import React from 'react';
import { Form, Select, Input, Checkbox, DatePicker, Radio, Divider, Button } from 'antd';
import Dailog from '../../../components/modal';
import PdfViewer from '../../../components/PdfViewer';
import { useState } from 'react';
import { LinkOutlined } from '@ant-design/icons';
import MobileFooter from './mobile-footer/mobile.footer';
import { routes } from '../../../actions/api-routes';
const { Option } = Select;

const JobDetails = ({ data, previous, next }) => {
  const [isVisible, setVisible] = useState(false)

  return (
    <>
      <div className='row'>
        <div className='col-lg-3 profile-view-box'>
          <h6>My Profile</h6>
          <div className='profile-view'>
            <div className='profile-box'>
              <Divider className='divider' />
              <div className='profile-lable'>
                Name
              </div>
              {data?.candidateName} | {data?.candidateType} ({data?.experiance} Years)
              <br />
              <Divider className='divider' />
            </div>
            <div className='profile-box'>
              <div className='profile-lable'>
                Contact Details
              </div>
              {data?.email}
              <br />
              {data?.phone}
              <Divider className='divider' />
            </div>
            <div className='profile-box'>
              <div className='profile-lable'>
                Salary
              </div>
              Current CTC in LPA - {data?.currentCTC} <br />
              Expected CTC in LPA - {data?.expectedCTC}
              <Divider className='divider' />
            </div>
            <div className='profile-box'>
              <div className='profile-lable'>
                Last Organization
              </div>
              Notice Period - {data?.noticePeriod} <br />
              Reason Of Leaving - {data?.reasonOfLeaving}
              <Divider className='divider' />
            </div>
            <div className='profile-box'>
              <div className='profile-lable'>
                Resume
              </div>
              <Button
                onClick={() => setVisible(true)}
                type='link'
                icon={<LinkOutlined />}
                className='no-padding'>
                View Resume
              </Button>
              <PdfViewer
                className='react-model'
                title={'Resume'}
                isOpen={isVisible}
                onClose={() => setVisible(false)}
                pdfUrl={`${routes.fileServer}${data?.resume}`}
              >
              </PdfViewer>
            </div>
          </div>
        </div>
        <div className='col-lg-9 scroll-view-box'>
          <div>
            <div className='heading-padding'>
              <h6>Job Discriptions</h6>
              <div className='button-box d-dextop'>
                <Button className='button-view margin-lr' onClick={previous}>Previous</Button>
                <Button className='button-view d-dextop' onClick={next} type='primary'>Next</Button>
              </div>
            </div>
            <Divider className='divider' />
            <p dangerouslySetInnerHTML={{ __html: data?.appliedFor?.jobDescription }} className='scroll-view'>
            </p>
          </div>
        </div>
      </div>
      <MobileFooter previous={previous} next={next} isPrevious={true} />
    </>
  );
};

export default JobDetails;
