import { useNavigate } from 'react-router-dom';
import './index.css';


import { Form, Input, Button, Checkbox } from 'antd';
import { validateEmail } from '../admin/common/validate';
import { getData, postData } from '../actions/middleware';
import { error, success } from '../admin/common/Notify';
import { SetCookies, getUserData } from '../actions/auth';
import { useEffect } from 'react';
import { routes } from '../actions/api-routes';

function LoginEmployee() {
  const navigate = useNavigate()

  const getAuthURL = async () => {
    const resData = await getData(routes.authRoutes.getUrl)
    console.log("resDataresDataresDataresData", resData)
    if (resData && resData.code == 200) {
      location.href = resData.data.url;
    }
  }

  useEffect(() => {
    getUserDetails();
  }, [])


  const getUserDetails = () => {
    const data = getUserData();
    if (data) {
      navigate('/admin')
    }
  }

  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div className="row">
          <div className="col-lg-4 col-md-4 center pt-10">
            <div className="projects-wrap style-04 wow move-up">
              <div className="projects-image-box">
                <div className="content">
                  <div className='text-center p-10'>
                    <h5 className='heading'>Welcome to Accelgrowth</h5>
                    <h6 className='heading'>To Continue sign with Google</h6>
                  </div>
                  <Button type="primary" htmlType='submit' onClick={getAuthURL} size={'large'} style={{ width: '100%' }}>
                    Continue
                  </Button>
                </div>
              </div>
              <div className='text-center'>
                <div>Don't have an account ? <Button className='no-padding' type='link' onClick={() => navigate('/auth/register')} >Sign up</Button> </div>
                <div> Please <Button type='link' className='no-padding'>Contact Us</Button> if you require any assistance  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginEmployee;
