const routes = {
  profiles: {
    getList: '/vendor/developers/',
    getPublic: '/vendor/developers/public/list',
    shortlistedProfiles: '/vendor/developers/shortlisted/profiles',
    admin_update: '/vendor/developers/admin/',
    adminList: '/vendor/developers/all/list',
    profileData: '/vendor/developers/profile'
  },
  send: {
    link: '/'
  },
  onlineTest: {
    questions: '/public/questions',
    testConfig: '/test/config'
  },
  jobs: {
    list: '/vendor/current_opening/',
    listWithFilter: '/vendor/current_opening/my/openings/list',
    bulk_job: '/vendor/current_opening/bulk/job/',
    listAll: '/vendor/current_opening/all',
    pendingCount: '/vendor/current_opening/pending/count',
    listAllForAdmin: '/vendor/current_opening/all/reviewer',
    getby: '/vendor/current_opening/find/job',
    bulk_upload: '/bulk_upload',
    bulk_upload_bulk: '/bulk_upload/all/list',
    get_job_details: '/vendor/current_opening/detail/view'
  },
  option: {
    list: '/public/list'
  },
  public: {
    jobList: '/public/current_opening',
    ownProfile: '/public/current_opening/userprofile/',
    getProfiles: '/public/developers/profile/',
    vendorOptions: '/public/vendor/options/list',
    link: '/public/link/for/public/use'
  },
  applied: {
    list_developer: '/vendor/applied/developers/get/shared/profiles',
    read: '/vendor/applied/developers/read/profile',
    profileApplied: '/vendor/applied/developers'
  },
  developer: {
    bulkUpload: '/vendor/bulk_upload/profiles/',
    bulkUploadList: '/vendor/bulk_upload/profiles/all/list',
  },
  authRoutes: {
    getUrl: '/google-meet/auth-token',
    verifyOTP: "/vendor/verify_otp"
  },
  slots: {
    listSlots: (interviewer, _day, currentDate) => `/slots/list/slots/for/${interviewer}/${_day}/${currentDate}`,
    bookSlot: '/public/book-meeting'
  },
  vendorInvite: {
    invite: '/vendor/invite'
  },
  interviewQuestions: '/interview_questions/',
  profileURL: '/update/profile/',
  fileUpload: '/upload/add',
  '#fileServer': 'http://localhost:3170',
  'fileServer': 'https://api.accelgrowth.in'
}

export { routes }