import React, { useState, useRef, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select, Space, Button, Form } from 'antd';
import { getData, postData } from '../../../actions/middleware';
import { decryptData } from '../../../actions/crypto';
const { Option } = Select;
const optionURL = '/public/list';

const DynamicSelect = ({ searchApi = optionURL, keyName, multiple, label, name, rules = [], fieldKey, addNew = true, ...restField }) => {
  const [optionValue, setOptionValue] = useState()
  const [optionsRes, setOptions] = useState([])

  useEffect(() => {
    getOptionData();
  }, [])

  const getOptionData = async (search = '') => {
    const resData = await getData([searchApi, keyName].join('/'), { search: search, keys: 'name' });
    if (resData && resData.code == 200) {
      const decryptedData = decryptData(resData.data);
      if (decryptedData.length > 0) {
        setOptions(decryptedData)
      } else {
        if (addNew) {
          setOptionValue(capitalizeString(search))
        }
      }
    }
  }

  const capitalizeString = (str) => {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const onSaveData = async () => {
    if (optionValue) {
      await postData(searchApi, { name: capitalizeString(optionValue), optionType: keyName });
    }
  }

  return (
    <Form.Item
      {...restField}
      label={label}
      name={name}
      rules={rules}
      fieldKey={fieldKey}
    >
      <Select
        showSearch={true}
        mode={multiple ? 'multiple' : 'single'}
        placeholder={'Type or select'}
        maxTagCount={1}
        maxTagTextLength={10}
        onSearch={(e) => {
          getOptionData(e)
        }}
        onChange={(e) => {
          if (optionValue == e || (Array.isArray(e) && e.includes(optionValue))) {
            onSaveData();
          }
        }}
      >
        {addNew && optionValue && <Option value={optionValue} className="capitalize">{optionValue}</Option>}
        {optionsRes.map((ele, index) => <Option key={index} className="capitalize" value={ele.name}>{ele.name}</Option>)}
      </Select>
    </Form.Item>
  );
};
export default DynamicSelect;